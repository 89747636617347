<template>
  <v-form v-model="valid">
    <v-alert
      v-if="$t('FORMS.APARTMENT_ACCESS_PERMISSION.DESCRIPTION').length"
      color="grey lighten-3"
    >
      {{ $t("FORMS.APARTMENT_ACCESS_PERMISSION.DESCRIPTION") }}
    </v-alert>

    <v-row dense>
      <v-col cols="12">
        <datepicker_
          v-model="data['DATE']"
          :label="$t('FORMS.APARTMENT_ACCESS_PERMISSION.DATE')"
          dense
        />
      </v-col>

      <v-col cols="6">
        <timepicker_
          v-model="data['TIME_FROM']"
          :label="$t('FORMS.APARTMENT_ACCESS_PERMISSION.TIME_FROM')"
          dense
        />
      </v-col>

      <v-col cols="6">
        <timepicker_
          v-model="data['TIME_TO']"
          :label="$t('FORMS.APARTMENT_ACCESS_PERMISSION.TIME_TO')"
          dense
        />
      </v-col>

      <v-col cols="12">
        <v-select
          v-model="localFormData.REASON"
          :rules="[rules.required]"
          :items="items"
          :label="$t('FORMS.APARTMENT_ACCESS_PERMISSION.REASON') + ' *'"
          hide-details
          solo
          :append-icon="errors.reason ? 'mdi-alert-circle-outline' : 'mdi-chevron-down'"
          @update:error="errors = { ...errors, reason: $event }"
        ></v-select>
      </v-col>

      <v-col cols="12">
        <v-checkbox :rules="[rules.required]" hide-details class="ma-0">
          <template v-slot:label v-html="regexCheckbox">
            <span v-html="regexCheckbox" />
          </template>
        </v-checkbox>
      </v-col>
    </v-row>

    <v-row dense text-center>
      <v-col cols="12">
        <slot name="button" :valid="valid" />
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <slot name="description" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Rules from "@/app/modules/_global/classes/Rules";
import { mask } from "vue-the-mask";

export default {
  name: "ApartmentAccessPermission",

  components: {
    datepicker_: () => import("@/app/modules/service/builder/elements/DatePicker.vue"),
    timepicker_: () => import("@/app/modules/service/builder/elements/TimePicker.vue")
  },

  directives: {
    mask
  },

  model: {
    prop: "formData"
  },

  props: {
    formData: {
      required: true,
      type: Object
    }
  },

  data: vm => ({
    data: {
      DATE: "",
      TIME_FROM: "",
      TIME_TO: ""
    },

    localFormData: null,

    items: [
      vm.$t("FORMS.APARTMENT_ACCESS_PERMISSION.REASONS[0]"),
      vm.$t("FORMS.APARTMENT_ACCESS_PERMISSION.REASONS[1]"),
      vm.$t("FORMS.APARTMENT_ACCESS_PERMISSION.REASONS[2]"),
      vm.$t("FORMS.APARTMENT_ACCESS_PERMISSION.REASONS[3]")
    ],

    valid: true,
    errors: {},

    rules: {
      required: Rules.required
    }
  }),

  computed: {
    buildingComplexData() {
      return this.$store.getters["app/buildingComplexData"];
    },

    managementCompany() {
      return this.buildingComplexData?.management_company;
    },

    regexCheckbox() {
      const searchRegExp = /\[COMPANY_NAME\]/g;
      const checkboxText = this.$t("FORMS.APARTMENT_ACCESS_PERMISSION.CHECKBOX");

      return checkboxText.replace(searchRegExp, `<b>${this.managementCompany.name}</b>`);
    }
  },

  watch: {
    localFormData: {
      deep: true,
      handler: "setFormData"
    }
  },

  created() {
    this.localFormData = Object.assign({}, this.formData);
  },

  methods: {
    setFormData() {
      this.$emit("input", { ...this.localFormData, ...this.data });
    }
  }
};
</script>
